/* eslint-disable */
import {default as React} from "react" 
import Layout from "~/components/layout"
import Seo from "~/components/seo"

const DisclosurePage = () => {
  return (
    <Layout>
      <Seo title={`Disclosures`} />
      <section className="py-20 bg-primary">
        <div className="container mx-auto px-8">
          <div className="flex -mx-8">
            <div className="w-full px-8">
                <h1 className="text-5xl">Disclosures</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="py-24">
        <div className="container mx-auto px-8">
          <div className="flex flex-wrap -mx-8">
            <div className="w-full md:w-1/2 px-8">
              
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DisclosurePage